.root {
}

.inputError {
  border: var(--borderErrorField);
}

.helperText{
  font-size: 14px;
  color: gray;
  text-align: end;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}